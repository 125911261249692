import * as React from "react"
import { Link } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import { RecCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/client-stories/",
    label: "Client Stories",
  },
]

const clientCard = [
  {
    rec: "../images/client-stories/VigilantMVDR.jpg",
    ariaLabel:
      "Read Success Story - Vigilant MDR - Large manufacturing company protects against data and revenue loss from ransomware and other advanced attacks with Vigilant.",
    subHeading: "SUCCESS STORY",
    title: "VigilantMVDR",
    url: "https://vigilant.sdcdn.app/pdf/client-stories/a-strategic-approach-to-ensuring-compliance-and-security.pdf",
    content:
      "Learn how this aerospace industry manufacturer reduced their attack surface and made progress in their compliance initiatives.",
  },
  {
    rec: "../images/client-stories/fr-card.png",
    ariaLabel:
      "Read Success Story - Vigilant MDR - Large manufacturing company protects against data and revenue loss from ransomware and other advanced attacks with Vigilant.",
    subHeading: "SUCCESS STORY",
    title: "Fast Remediation",
    url: "/cloud-monitoring/case-study",
    content:
      "Learn how a large manufacturing company stopped a systemic vulnerability that was putting their reputation and future in jeopardy.",
  },
  {
    rec: "../images/client-stories/VigilantMEDR.jpg",
    ariaLabel:
      "Read Success Story - Vigilant MDR - Large manufacturing company protects against data and revenue loss from ransomware and other advanced attacks with Vigilant.",
    subHeading: "SUCCESS STORY",
    title: "Vigilant365™",
    url: "/account-takeover/case-study",
    content:
      "Mid-size technology company prevents unauthorized access to Microsoft 365 account with Vigilant.",
  },
  {
    rec: "../images/resource/band_cardimage.jpg",
    ariaLabel:
      "Read Success Story - Vigilant MDR - Large manufacturing company protects against data and revenue loss from ransomware and other advanced attacks with Vigilant.",
    subHeading: "SUCCESS STORY",
    title: "VigilantMNDR",
    url: "/bandwidth/case-study",
    content:
      "A large healthcare company reduced bandwidth troubleshooting from weeks to minutes.",
  },
  {
    rec: "../images/client-stories/VigilantMNDR.jpg",
    ariaLabel:
      "Read Success Story - Vigilant MDR - Large manufacturing company protects against data and revenue loss from ransomware and other advanced attacks with Vigilant.",
    subHeading: "SUCCESS STORY",
    title: "SECURITY MATURITY",
    url: "/security-maturity/case-study",
    content:
      "Major retailer changed endpoint solution to meet present-day needs.",
  },
  {
    rec: "../images/client-stories/VigilantMDR.jpg",
    ariaLabel:
      "Read Success Story - Vigilant MDR - Large manufacturing company protects against data and revenue loss from ransomware and other advanced attacks with Vigilant.",
    subHeading: "SUCCESS STORY",
    title: "VigilantMDR",
    url: "https://sp1.sdcdn.app/pdf/MDR-Success-Story.pdf",
    content:
      "Large manufacturing company protects against data and revenue loss from ransomware and other advanced attacks with Vigilant. ",
  },
  {
    rec: "../images/client-stories/VigilantMEDR.jpg",
    ariaLabel:
      "Read Success Story - VigilantMEDR - National retail company avoids a potentially expensive and damaging malware attack with Vigilant.",
    subHeading: "SUCCESS STORY",
    title: "VigilantMEDR",
    url: "https://sp1.sdcdn.app/pdf/MEDR-Success-Story.pdf",
    content:
      "National retail company avoids a potentially expensive and damaging malware attack with Vigilant.",
  },
  {
    rec: "../images/client-stories/VigilantMNDR.jpg",
    ariaLabel:
      "Read Success Story - VigilantMNDR - Large packaging supply chain company gets holisitic visibility into their network with Vigilant.",
    subHeading: "SUCCESS STORY",
    title: "VigilantMNDR",
    url: "https://sp1.sdcdn.app/pdf/MNDR-Success-Story.pdf",
    content:
      "Large packaging supply chain company gets holisitic visibility into their network with Vigilant.",
  },
  {
    rec: "../images/client-stories/Vigilant365.jpg",
    ariaLabel: "Read Success Story - Vigilant365",
    subHeading: "SUCCESS STORY",
    title: "Vigilant365™",
    url: "https://sp1.sdcdn.app/pdf/V365-Success-Story.pdf",
    content:
      "Mid-size technology company prevents unauthorized access to Microsoft 365 account with Vigilant. ",
  },
  {
    rec: "../images/client-stories/PenTest.png",
    ariaLabel: "Read Success Story - Penetration Testing",
    subHeading: "SUCCESS STORY",
    title: "PENETRATION TESTING",
    url: "https://sp1.sdcdn.app/pdf/PenTesting_SuccessStory_V2.pdf",
    content:
      "Mid-size Technology company utilizes Pen Testing to effectively identifies weaknesses with remediation that keeps threat actors from holding data for ransom.",
  },
]
const ClientStories = () => (
  <Layout>
    <Seo title="Client Stories" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <div className="grid--bg--for-it-ops-3">
      <section className="section-title-top ">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>CLIENT STORIES</h1>
              <p className="content">
                Because of the stories we share and the sustained success we
                experience together, Vigilant is honored by the long-term
                relationships we have with our clients.
              </p>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>

      <section className="section-client-cards">
        <div className="container">
          <div className="row">
            <div className="section-header">
              <hr />
              <h6>ALL STORIES</h6>
            </div>
          </div>
          <div className="row">
            <ul className="client-cards-lists">
              {clientCard.map(data => {
                return (
                  <RecCard
                    ariaLabel={data.ariaLabel}
                    imgSrc={data.rec}
                    subHeader={data.subHeading}
                    title={data.title}
                    content={data.content}
                    altTxt={data.title}
                    hrf={data.url}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </section>
    </div>

    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default ClientStories
